@import "./src/assets/scss/main.scss";






































































#printMe {
    background: #000;
    width: 302px;
    height: 755px;
    color: azure;
}
